import React from "react";
import { navigate } from "gatsby";
import { Virus } from "tabler-icons-react";

import Layout from "../components/layout";

import SEO from "../components/seo";
import Hero from "../components/hero";
import ContentBox from "../components/contentbox";
import Faq from "../components/faq";
import Team from "../components/team";
import Banner from "../components/banner";
import Wrapper from "../components/wrapper";

const IndexPage = () => (
  <Layout>
    <SEO title="Für Patienten" keywords={[`gatsby`, `application`, `react`]} />
    <Hero
      title="Ihr Spezialist für Wundbehandlung"
      desc="WKM Rösner ist ein modernes medizinisches Zentrum für die Versorgung chronischer Wunden. Wir bieten in Mönchengladbach und Willich die bestmögliche Wundbehandlung auf zertifiziertem und standardisiertem Qualitätsniveau."
      to={"/kontakt"}
    />
    <Wrapper pt="3rem">
      <Banner
        icon={<Virus size={88} />}
        title="Wichtiger Hinweis zum Umgang mit COVID-19"
        message="Während der COVID-19 Pandemie steht Ihnen unsere Praxis weiterhin zur Verfügung. Bitte kommen Sie ohne Begleitperson (Ausnahme, wenn erforderlich) und betreten Sie unsere Räume nur mit Mund-Nase-Schutzmaske."
      />
    </Wrapper>
    <ContentBox
      image={require("../images/pflaster.jpg")}
      title="Chronische Wunden in den besten Händen"
      desc="Wer wüsste besser als Sie, wie schmerzhaft und langwierig chronische Wunden sind. Wenn Sie – wie ca 3,5 Mio. Menschen in Deutschland – unter diabetischem Fuß, offenen Beinen, Liegegeschwüren (Dekubitus) oder Wunden nach Verletzungen und Verbrennungen leiden, sind Sie bei WKM Rösner in besten Händen."
    />
    <ContentBox
      image={require("../images/shutterstock_317585759.jpg")}
      title="Kompetente Komplettbetreuung"
      desc="In Absprache mit dem behandelnden Arzt versorgt unser Team professionell und sorgsam Ihre Wunde nach neusten wissenschaftlichen Erkenntnissen. Bei Bedarf werden weitere Berufsgruppen wie zum Beispiel Podologen, orthopädische Schuhmacher, Lymphtherapeuten sowie Pflegekräfte hinzugezogen. <br /><br />
      So beraten und unterstützen wir Sie bei benötigten Lagerungsmaterialien für eine Druckentlastung, der Hautpflege und den Wundmaterialien. Und bei Bedarf stellen wir Ihnen geschulte Pflegefachkräfte zur Verfügung, die Sie bei Ihnen zuhause 
      versorgen."
      imageFirst
    />
    <Team />
    <Faq />
  </Layout>
);

export default IndexPage;
